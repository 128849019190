import React, { FC } from 'react'
import { graphql, PageProps } from 'gatsby'
import { useQueryParam, StringParam } from 'use-query-params'
import { withPreview } from 'gatsby-source-prismic'

import Layout from '../components/Layout'
import '../components/base.css'
import { ReferralQuery } from '../../graphql-types'
import extractGlobals from '../lib/extractGlobals'
import EmailReferralForm from '../components/EmailReferralForm'
import {
  Section,
  SectionContent,
  SectionTitle,
} from '../components/FrontMatter'
import { mq, spaces, styled, typography } from '../styles'
import useReferral from '../lib/useReferral'
import SanityFluidImage from '../components/SanityFluidImage'

const Referral: FC<PageProps<ReferralQuery>> = ({ data }) => {
  const { meta, footer } = extractGlobals(data)
  const [type, setType] = useQueryParam('c', StringParam)

  const { referral } = useReferral(type ? type : 'default')

  return (
    <Layout tabNames={[]} meta={meta} footer={footer}>
      {referral ? (
        <Section>
          <Title>{referral.title}</Title>
          <Subtitle>{referral.body}</Subtitle>
          <DecoImage
            imageAsset={referral.image.asset}
            url={referral.imageUrl}
            width={386}
            sizes={'(max-width: 800px) 100vw, 385px'}
          />
          <SectionContent>
            <EmailReferralForm
              theme={'light'}
              bodyTemplate={referral.emailBody}
            />
          </SectionContent>
        </Section>
      ) : (
        <LoadingSpace />
      )}
    </Layout>
  )
}

export default withPreview(Referral)

const Title = styled(SectionTitle)(
  mq({
    paddingBottom: spaces.medium,
    width: ['60%', '80%'],
    margin: 'auto',
    lineHeight: 0.85,
  }),
)

const Subtitle = styled.div(
  typography.tinyMono,
  mq({
    textAlign: 'center',
    margin: 'auto',
    marginBottom: spaces.medium,
    width: ['60%', '80%'],
  }),
)

const DecoImage = styled(SanityFluidImage)(
  mq({
    maxWidth: '100%',
    width: 385,
    margin: '15px auto',
    marginBottom: spaces.large,
  }),
)

const LoadingSpace = styled.div({
  minHeight: '100vh',
})

export const pageQuery = graphql`
  query Referral {
    prismicSplash {
      _previewable
      data {
        ...Meta
        ...Footer
      }
    }
    allSanityWebReferral(filter: { documentName: { eq: "Default" } }) {
      nodes {
        body
        emailBody
        image {
          _rawAsset
          asset {
            gatsbyImageData
            url
          }
        }
        title
      }
    }
  }
`
