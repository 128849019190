import { ThemeProvider } from '@emotion/react'
import React, { FC, useCallback, useRef, useState } from 'react'
import { SubscribeState } from '../lib/SubscribeUtils'
import { fonts, mq, spaces, styled, typography } from '../styles'
import Button from './Button'
import { SignupFormTheme, themes } from './EmailSignupForm'

const useEmail = (bodyTemplate: string) => {
  const [email, setEmail] = useState('')
  const [name, setName] = useState('')
  const [body, setBody] = useState(bodyTemplate)
  const [errorMessage, setErrorMessage] = useState<string | undefined>()
  const [subscribeState, setSubscribeState] = useState<SubscribeState>(
    SubscribeState.Initial,
  )

  const emailInputRef = useRef(null)

  const handleInputChanged = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const target = event.target
      const value = target.value
      if (target.name === 'name') setName(value)
      else setEmail(value)
    },
    [],
  )

  const handleTextareaChanged = useCallback(
    (event: React.ChangeEvent<HTMLTextAreaElement>) => {
      const target = event.target
      const value = target.value
      setBody(value)
    },
    [],
  )

  const handleSubmit = useCallback(
    async (event: React.MouseEvent<HTMLButtonElement>) => {
      event.preventDefault()
      if (email === '') {
        setErrorMessage('Please fill in your name and email.')
        setSubscribeState(SubscribeState.Failed)
      } else {
        setSubscribeState(SubscribeState.Started)
      }
    },
    [name, email],
  )

  return {
    name,
    email,
    body,
    emailInputRef,
    handleInputChanged,
    handleTextareaChanged,
    handleSubmit,
    errorMessage,
    state: subscribeState,
  }
}

const EmailReferralForm: FC<{
  bodyTemplate: string
  theme: 'light' | 'dark'
}> = ({ theme = 'light', bodyTemplate }) => {
  const signup = useEmail(bodyTemplate)

  return (
    <ThemeProvider theme={themes[theme]}>
      <Form>
        {signup.state !== SubscribeState.Complete && (
          <>
            <TextInputs>
              <Input
                name="email"
                type="email"
                placeholder="To Email"
                value={signup.email}
                onChange={signup.handleInputChanged}
              />
              <Input
                name="name"
                placeholder="From Name"
                value={signup.name}
                onChange={signup.handleInputChanged}
              />
              <TextArea
                name="body"
                placeholder="Email Body"
                rows={10}
                value={signup.body}
                onChange={signup.handleTextareaChanged}
              />
              {signup.state == SubscribeState.Failed && (
                <ErrorMessage>{signup.errorMessage}</ErrorMessage>
              )}
            </TextInputs>

            <BigButton
              size={'large'}
              colorScheme={theme === 'dark' ? 'light' : 'dark'}
              disabled={signup.state === SubscribeState.Started}
              onClick={signup.handleSubmit}>
              Invite
            </BigButton>
          </>
        )}

        {signup.state === SubscribeState.Complete && (
          <SuccessMessage>
            The invitation is sent!
            <br />
            Looking forward to listening to Oda with you.
          </SuccessMessage>
        )}
      </Form>
    </ThemeProvider>
  )
}

export default EmailReferralForm

const Form = styled.form(mq({}))

const TextInputs = styled.div(
  mq({
    width: [1000, 'auto'],
    maxWidth: ['100%'],
    margin: '0 auto',
    marginBottom: spaces.small,
  }),
)

const Input = styled.input(({ theme: t }) => {
  const theme = t as SignupFormTheme
  return mq({
    display: 'block',
    width: [670, '100%'],
    maxWidth: '100%',
    border: theme.inputBorder,
    borderRadius: [10, 7, 7, 7],
    padding: '10px 20px 10px 20px',
    margin: '0 auto',
    marginBottom: spaces.small,
    backgroundColor: theme.inputBackgroundColor,
    fontFamily: fonts.monosten.light,
    fontSize: ['80px', '32px', '32px'],
    '::-webkit-input-placeholder': {
      color: theme.placeholderColor,
    },
    ':-webkit-autofill': {
      boxShadow: `0px 0px 0px 60px #E6E1DA inset`,
    },
    ':-webkit-autofill::first-line': {
      fontSize: ['80px', '72px', '72px'],
    },
    ':last-child': {
      marginBottom: [0, 0, 0, 0],
    },
    ':focus': {
      borderRadius: 10,
      outline: 'none',
      boxShadow: `2px 2px 0px grey`,
    },
  })
})

const TextArea = styled.textarea(({ theme: t }) => {
  const theme = t as SignupFormTheme
  return mq({
    display: 'block',
    width: [670, '100%'],
    maxWidth: '100%',
    border: theme.inputBorder,
    borderRadius: [10, 7, 7, 7],
    padding: '10px 20px 10px 20px',
    margin: '0 auto',
    marginBottom: spaces.small,
    backgroundColor: theme.inputBackgroundColor,
    fontFamily: fonts.monosten.light,
    fontSize: ['20px', '15px', '15px'],
    '::-webkit-input-placeholder': {
      color: theme.placeholderColor,
    },
    ':-webkit-autofill': {
      boxShadow: `0px 0px 0px 60px #E6E1DA inset`,
    },
    ':-webkit-autofill::first-line': {
      fontSize: ['20px', '15px', '15px'],
    },
    ':last-child': {
      marginBottom: [0, 0, 0, 0],
    },
    ':focus': {
      borderRadius: 10,
      outline: 'none',
      boxShadow: `2px 2px 0px grey`,
    },
  })
})

const ErrorMessage = styled.div(
  typography.tinyMono,
  mq({
    textAlign: 'center',
  }),
)

const SuccessMessage = styled.div(
  typography.smallSans,
  mq({
    textAlign: 'center',
  }),
)

const BigButton = styled(Button)(
  mq({
    width: ['875px', '100%'],
    maxWidth: '100%',
    margin: '0 auto',
  }),
)
